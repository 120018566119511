import React, {useState, useEffect} from 'react'
import {KTSVG} from '../../_metronic/helpers'
import {PageTitle} from '../../_metronic/layout/core'
import axios from 'axios'
import ReactPaginate from 'react-paginate'
import {toast} from 'react-toastify';
import Modal from 'react-bootstrap/Modal'

import { DateRangePicker } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import { EyeFill } from 'react-bootstrap-icons'


const Leads = () => {
    const token =  localStorage.getItem('kt-auth-react-v.api_token')
    const [offset, setOffset] = useState(0);
    const [fromDate, setFromDate] = useState(''); 
    const [toDate, setToDate] = useState('');
    const [sortBy, setSortBy] = useState(-1);
    const [contentLoading, setContentLoading] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allData, setAllData] = useState([]);
    const [pageCount, setPageCount] = useState(0)
    const [totalItems, setTotalItems] = useState(0)
    const [dataPerPage, setDataPerPage] = useState(10)
    const [lgshow, setShow] = useState(false);
    const [leadTypeFilter, setLeadTypeFilter] = useState('');
    const [viewedFilter, setViewedFilter] = useState('');

    const [modalTitle, setModalTitle] = useState('');
    const [itemData, setItemData] = useState({});

    const [calenderDates, setCalenderDates] = useState([
        {
            startDate: null,
            endDate: new Date(),
            key: 'selection',
            
        }
    ]);

    const handleClose = () => {
        setItemData({});
        setShow(false);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * dataPerPage);
        // console.log(newOffset);
        setOffset(newOffset);
        setDataPerPage(dataPerPage);
    };

    useEffect(() => {
        // paginate to first page
        setOffset(0);
        getData();
    }, [sortBy, offset, calenderDates, fromDate, toDate, searchQuery, leadTypeFilter, dataPerPage, viewedFilter])

    const handleLeadType = async(e, id) => {
        const status = e.target.value;
        if(status === ''){
            return;
        }
        const data = {
            status
        }
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/leads/${id}`, data, { headers: {"Authorization" : ` ${token}`} })
        if(res.data.status === 200){
            toast.success(res.data.message);
            getData();
            handleClose();
        }else{
            toast.error(res.data.message);
        }
    }



    const getAllData = (data, offset) => {
        return (
            
            data.map(item =>{
                // const blog_data = order.order_details[0];
                return (
                <tr key={item._id} 
                    className={`widget-9-item ${item?.viewed ? 'bg-white' : 'bg-light-warning'}`}
                    >
                    <td></td>
                    <td>
                        {item?.name}
                    </td>
                    <td>
                        {item?.email}
                    </td>
                    <td>
                        {item?.phone}
                    </td>
                    <td>
                        {/* status */}
                        {/* ['new', 'contacted', 'qualified', 'lost'] */}
                        {/* <span className={`badge badge-light-${item?.status === 'new'?'danger':item?.status === 'contacted'?'info':item?.status === 'qualified'?'success':'warning'}`}>{item?.status}</span> */}
                            <select aria-label="Default select example" className="form-select form-select-sm text-nowrap border-0 bg-light" onChange={e=>handleLeadType(e, item._id)} value={item.status}>
                                <option value="">Select</option>
                                <option value="new">New</option>
                                <option value="contacted">Contacted</option>
                                <option value="qualified">Qualified</option>
                                <option value="lost">Lost</option>
                            </select>
                    </td>
                    <td>
                        {/* is_deleted */}
                        <span className={`badge badge-light-${item?.is_deleted ? 'danger' : 'success'}`}>{item?.is_deleted ? 'Deleted' : 'Active'}</span>
                    </td>
                   
                    <td className=''>
                        <div className='d-flex flex-shrink-0'>
                            <button onClick={() => viewDetails(item?._id, item)}
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1' title='View Details'
                            >
                                {/* <KTSVG
                                    path='/media/icons/duotune/general/gen028.svg'
                                    className='svg-icon-3 text-primary'
                                /> */}
                                <EyeFill
                                    className='text-primary'
                                />
                            </button>     

                            {item?.is_deleted ? '' : (
                                <button onClick={() => deleteItem(item._id)}
                                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm '
                                    >
                                        <KTSVG
                                            path='/media/icons/duotune/general/gen027.svg'
                                            className='svg-icon-3 text-primary text-white'
                                        />
                                </button>   )}
                            
                        </div>
                    </td>
                </tr>
                )
            })
        )
    }
    
    const getData = async () => {
        setContentLoading(true);
        try{
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/leads?from_date=${fromDate}&to_date=${toDate}&sort=${sortBy}&keyword=${searchQuery}&offset=${offset}&limit=${dataPerPage}&status=${leadTypeFilter}&viewed=${viewedFilter}`, { headers: {"Authorization" : ` ${token}`} })
            
            const data = res.data;
            const page_data = data.leads;
            const pageData = getAllData(page_data);
            // console.log(pageData);
            setAllData(pageData);
            setPageCount(Math.ceil(data.total / dataPerPage));
            setTotalItems(data.total);
        }catch(error){
            console.log(error);
            toast.error("Something went wrong");
        }finally{
            setContentLoading(false);
        }
        
    }

    const viewDetails = (id, item) => {
        setItemData(item);
        setModalTitle('Lead Details');
        setShow(true);
        handleViewed(id);
        
    }

    const deleteItem = (id) => {
        if(window.confirm('Are you sure you want to delete this item?')){
            axios.delete(`${process.env.REACT_APP_API_URL}/leads/${id}`, { headers
                : {"Authorization" : ` ${token}`} })
            .then(res => {
                if(res.data.status === 200){
                    toast.success(res.data.message);
                    getData();
                }else{
                    toast.error(res.data.message);
                }
            }
            )
            .catch(error => {
                console.log(error);
                toast.error("Something went wrong");
            }
            )
        }
    }

    const handleViewed = async(id) => {
        const data = {
            viewed: true
        }
        const res = await axios.patch(`${process.env.REACT_APP_API_URL}/leads/${id}`, data, { headers: {"Authorization" : ` ${token}`} })
        if(res.data.status === 200){
            getData();
        }else{
            toast.error(res.data.message);
        }
    }

    const normalizeDate = (date) => {
        const offset = date.getTimezoneOffset(); // Offset in minutes
        return new Date(date.getTime() - offset * 60 * 1000);
    };
    
    const handleDateChange = (range) => {
        if (range) {
            const [start, end] = range;
            setFromDate(normalizeDate(start).toISOString().split("T")[0]); // YYYY-MM-DD
            setToDate(normalizeDate(end).toISOString().split("T")[0]);
        }
    };
    

        
    return (
        <>
            <PageTitle breadcrumbs={[]}>Manage Leads</PageTitle>
            <div className={`card card-light`}>
                <div className='card-header border-0 pt-5'>
                    <h3 className='card-title align-items-start flex-column'>
                        <span className='card-label fw-bold fs-3 mb-1'>Total Leads <span className='p-1 mt-1 fw-semibold fs-7 bg-primary text-white rounded'>{totalItems < 10 ? '0' + totalItems : totalItems}</span></span>
                    </h3>
                </div>
                <div className="d-flex flex-wrap px-5 bg-light p-3 shadow shadow-sm border">
                    <div className="row w-100">
                        {/* First row */}
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Search</label>
                            <input
                                type="text"
                                className="form-control form-control-sm"
                                placeholder="Search"
                                onChange={(e) => setSearchQuery(e.target.value)}
                                value={searchQuery}
                            />
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Lead Type</label>
                            <select
                                aria-label="Default select example"
                                className="form-select form-select-sm"
                                onChange={(e) => setLeadTypeFilter(e.target.value)}
                                value={leadTypeFilter}
                            >
                                <option value="">Lead Type</option>
                                <option value="new">New</option>
                                <option value="contacted">Contacted</option>
                                <option value="qualified">Qualified</option>
                                <option value="lost">Lost</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Items per Page</label>
                            <select
                                className="form-select form-select-sm"
                                value={dataPerPage}
                                onChange={(e) => setDataPerPage(e.target.value)}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="500">500</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Read / Unread</label>
                            <select
                                className="form-select form-select-sm"
                                value={viewedFilter}
                                onChange={(e) => setViewedFilter(e.target.value)}
                            >
                                <option value="">All</option>
                                <option value="true">Read</option>
                                <option value="false">Unread</option>
                            </select>
                        </div>

                        {/* Second row */}
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Date Range</label>
                            <DateRangePicker
                                onChange={(value) => handleDateChange(value)}
                                placeholder="Select Date Range"
                                value={calenderDates}
                            />
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label">Sort By</label>
                            <select
                                className="form-select form-select-sm"
                                value={sortBy}
                                onChange={(e) => setSortBy(e.target.value)}
                            >
                                <option value="-1">Latest</option>
                                <option value="1">Oldest</option>
                            </select>
                        </div>
                        <div className="col-md-3 col-6 mb-4">
                            <label className="form-label d-block">&nbsp;</label>
                            <button
                                className="btn btn-sm w-50 btn-dark"
                                onClick={() => {
                                    setSearchQuery('');
                                    setLeadTypeFilter('');
                                    setDataPerPage(10);
                                    setViewedFilter('');
                                    setFromDate('');
                                    setToDate('');
                                    setSortBy(-1);
                                }}
                            >
                                Clear Filters
                            </button>
                        </div>
                    </div>
                </div>

                <div className='card-body py-3'>
                    <div className='table-responsive'>
                        <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                            <thead>
                                <tr className='fw-bold'>
                                    <th className='w-25px'>
                                        <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                            <input
                                                className='form-check-input'
                                                disabled
                                                type='checkbox'
                                                value='1'
                                                data-kt-check='true'
                                                data-kt-check-target='.widget-9-check'
                                            />
                                        </div>
                                    </th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                    <th>Lead Type</th>
                                    <th>Status</th> 
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {contentLoading ? (
                                    <tr>
                                        <td colSpan='6' className='text-center'>
                                            <div className='spinner-border' size="sm" role='status'>
                                                <span className='visually-hidden'>Loading...</span>
                                            </div>
                                        </td>
                                    </tr>
                                ) : (
                                    allData.length === 0 ? (
                                        <tr>
                                            <td colSpan='6' className='text-center'>
                                                No data found
                                            </td>
                                        </tr>
                                    ) : (
                                        allData
                                    )
                                )}
                            </tbody>
                        </table>
                        <div className='text-center'>
                            <ReactPaginate
                                previousLabel={"Previous"}
                                nextLabel={"Next"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination mb-5"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"} />
                        </div>
                    </div>
                </div>
            </div>
            {/* ====================================== */}
                            {/* Modal */}
            {/* ====================================== */}
            
            <Modal onHide={handleClose} className='modal fade' size="lg" show={lgshow}>
                <div className='modal-header pb-0 border-0 justify-content-end'>
                    <div
                    className='btn btn-sm btn-icon btn-active-color-primary'
                    data-bs-dismiss='modal'
                    onClick={handleClose}
                    >
                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
                    </div>
                </div>
                <Modal.Body className='modal-body scroll-y mx-5 mx-xl-18 pt-0 pb-15'>
                    <div className='text-center mb-13'>
                    <h4 className='mb-3'>{modalTitle}</h4>
                    </div>
                    <div className='d-flex flex-column gap-4'>
                    <div className='mb-3'>
                        <p className='fw-bold'>Name:</p>
                        <p>{itemData.name}</p>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Phone:</p>
                        <p>{itemData.phone}</p>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Email:</p>
                        <p>{itemData.email}</p>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Lead Type:</p>
                        <p>
                            <span className={`badge badge-light-${itemData?.status === 'new'?'danger':itemData?.status === 'contacted'?'info':itemData?.status === 'qualified'?'success':'warning'}`}>{itemData?.status}</span>
                        </p>
                        <div className='w-25 mt-2'>
                            <select aria-label="Default select example" className="form-select form-select-sm text-nowrap border-0 bg-light" onChange={e=>handleLeadType(e, itemData._id)} value={itemData.status}>
                                <option value="">Select</option>
                                <option value="new">New</option>
                                <option value="contacted">Contacted</option>
                                <option value="qualified">Qualified</option>
                                <option value="lost">Lost</option>
                            </select>
                        </div>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Status:</p>
                        <p>
                            <span className={`badge badge-light-${itemData?.is_deleted ? 'danger' : 'success'}`}>
                                {itemData?.is_deleted ? 'Deleted' : 'Active'}
                            </span>
                        </p>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Comment:</p>
                        <p>{itemData.comment}</p>
                    </div>
                    <div className='mb-3'>
                        <p className='fw-bold'>Property URL:</p>
                        <a href={`${process.env.REACT_APP_FRONT_URL}/property/${itemData.property_url}`} target='_blank' rel='noopener noreferrer' className='text-primary'>
                        {`${process.env.REACT_APP_FRONT_URL}/property/${itemData.property_url}`}
                        </a>
                    </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default Leads
