import React from 'react';
import moment from 'moment';

const LeadsDataTable = ({ data }) => {
    // If data is undefined, return null to prevent rendering
    if (!data || data.length === 0) {
        return <div className="text-center text-muted">No data available</div>;
    }

    // Extract unique field names dynamically
    console.log("data", data);

    const fieldsName = Array.from(
        new Set(data.flatMap((item) => item.field_data.map((field) => field.name)))
    );

    // Function to convert field names into user-friendly labels
    const formatFieldName = (fieldName) => {
        return fieldName
        .replace(/_/g, ' ') // Replace underscores with spaces
        .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize first letter of each word
    };

    return (
        <div className='table-responsive'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                <thead>
                <tr className='fw-bold'>
                    <th>Created Time</th>
                    {fieldsName.map((field, index) => (
                        <th key={index}>{formatFieldName(field)}</th>
                    ))}
                </tr>
                </thead>
                <tbody>
                {data.map((lead, index) => {
                    // Convert field_data array to a flat object for easy access
                    const leadData = Object.fromEntries(
                        lead.field_data.map((field) => [field.name, field.values.join(', ')])
                    );

                    return (
                    <tr key={index} className="table-row">
                        <td>
                        {moment(lead.created_time, 'YYYY-MM-DD HH:mm:ss').format(
                            'MMM D, YYYY h:mm A'
                        )}
                        </td>
                        {fieldsName.map((field, fieldIndex) => (
                        <td key={fieldIndex}>
                            {formatFieldName(field) === 'Email' ? (
                            <a href={`mailto:${leadData[field]}`}>{leadData[field]}</a>
                            ) : formatFieldName(field) === 'Phone Number' ? (
                            <a href={`tel:${leadData[field]}`} className='text-primary'>
                                {leadData[field]}
                            </a>
                            ) : formatFieldName(field) === 'When Do You Plan To Invest?' ? (
                            <>{leadData[field]}</>
                            ) : (
                            leadData[field]
                            )}
                        </td>
                        ))}
                    </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
};

export default LeadsDataTable;
