import React, {useState, useEffect} from 'react'
import FacebookAuth from 'react-facebook-auth';
// import FacebookAuth from 'react-facebook-login';
import { DoorClosed, Facebook} from 'react-bootstrap-icons';
// import Loading from './LoadingPage';
import axios from 'axios';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Stepper, Step } from "react-form-stepper";
import LeadsDataTable  from '../component/LeadsDataTable';
// import leadsData  from '../component/leads.json';

const facebook_client_id = 496044416787966;
const ads_account = "1151271985951786"

const MyFacebookButton = ({ onClick }) => (
    <button onClick={onClick} className='btn btn-primary btn-sm rounded-0 btn-sm py-2 text-align-center mt-3'>
        <Facebook className='mr-2' size={20} /> Login with facebook
    </button>
);


const FbLeads = () => {
    // const rawLeads = leadsData;
    const[loading, setLoading] = useState(false);
    const [loading_text, setLoadingText] = useState('Loading...');
    const [adAccounts, setAdAccounts] = useState([]);
    const [ads, setAds] = useState([]);
    const [longLiveToken, setLongLiveToken] = useState('');
    const [leads, setLeads] = useState([]);
    const [goSteps, setGoSteps] = useState(0);
    
    // Retrieve the JSON string from localStorage
    const authData = JSON.parse(localStorage.getItem('kt-auth-react-v'));
    const token = authData.api_token;
    const userId = authData.user;

    useEffect(() => {
        getFacebookToken();
    }, []);
    
    const getFacebookToken = async () => {
        setLoading(true);
        await axios.get(`${process.env.REACT_APP_API_URL}/adminuser/facebook-token/${userId}`, {
            headers: {
                "Authorization": `${token}`
            }
        })
        .then(res => {
            // console.log(res);
            if(res.data.facebook_token){
                setLongLiveToken(res.data.facebook_token);
                setGoSteps(1)
                getAdAccounts(res.data.facebook_token);
            }
        })
        .catch(err => {
            console.log(err);
            setGoSteps(0)
            
            // toast.error('An error occured while getting facebook token')
        }).finally(() => {
            setLoading(false);
        })
    }

    const authenticate = (response) => {
        // console.log(response);
        setLoadingText('Generating Long Lived Token...')
        setLoading(true);
        if(response.accessToken){
            getLongLivedToken(response.accessToken);
            setLoading(false);
            setLoadingText('Loading...');
            return;
        }else{
            console.log('No access token');
            setLoading(false);
            setLoadingText('Loading...');
            return;
        }
    }
    const getLongLivedToken = async (short_lived_token) => {
        await axios.post(`${process.env.REACT_APP_API_URL}/adminuser/exchange-token`, {
            short_live_token: short_lived_token,
            user_id: userId
        }, {
            headers: {
                "Authorization": `${token}`
            }
        }
        )
        .then(res => {
            // console.log(res);
            if(res.data.access_token){
                setLoadingText('Getting Ad Accounts...')
                setLongLiveToken(res.data.access_token);
                setGoSteps(1);
                getAdAccounts(res.data.access_token);
            }
        }
        )
        .catch(err => {
            console.log(err);
            toast.error('An error occured while getting long lived token')
        }
        )
    }

    const getAdAccounts = async (long_lived_token) => {
        try {
            const response = await axios.get(
                `https://graph.facebook.com/v21.0/me?fields=adaccounts{name,account_id,created_time}&access_token=${long_lived_token}`
            );
    
            if (response.data.adaccounts?.data) {
                setLoadingText('Getting Ad Account...');
                
                // Find the specific ad account
                const specificAdAccount = response.data.adaccounts.data.find(
                    (account) => account.account_id === ads_account
                );
    
                if (specificAdAccount) {
                    setGoSteps(1);
                    setAdAccounts([specificAdAccount]); // Update state with only the specific account
                } else {
                    toast.warning('Ad account not found');
                }
            }
        } catch (err) {
            console.error(err);
            toast.error('An error occurred while getting ad accounts');
        }
    };

    const getAllAds = async (ad_account_id) => {
        // console.log(ad_account_id);
        setLoadingText('Getting Ads...')
        setLoading(true);
        // return;
        await axios.get(`https://graph.facebook.com/v21.0/${ad_account_id}/ads?status{ACTIVE}&fields=name,adset_id,created_time,status,insights{spend,impressions,clicks,inline_link_clicks,inline_post_engagement,reach,actions}&configured_status=ACTIVE&access_token=${longLiveToken}`)
        .then(res => {
            // console.log(res);
            if(res.data.data){
                // console.log(res.data.data);
                setGoSteps(2);
                setAds(res.data.data);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error('An error occured while getting ads')
        }).finally(() => {
            setLoading(false);
            setLoadingText('Loading...');
        })

    }

    const getLeads = async (ad_id) => {
        // console.log(ad_id);
        setLeads([]);
        setLoadingText('Getting Leads...')
        setLoading(true);
        // ?fields=field_data&access_token=${longLiveToken}`)
        await axios.get(`https://graph.facebook.com/v21.0/${ad_id}/leads?
            fields=created_time,form_id,form_name,field_data&
            access_token=${longLiveToken}`)
        .then(res => {
            if(res.data.data){
                setGoSteps(3);
                setLeads(res.data.data);
            }
        })
        .catch(err => {
            console.log(err);
            toast.error('An error occured while getting leads')
        })
        .finally(() => {
            setLoading(false);
            setLoadingText('Loading...');
        })

    }


    const setGoStepsOpen = (step) => {
        if(step === 0){
            return;
        }
        setGoSteps(step);
    }
    
    const logoutFB = async () => {
        await axios.patch(`${process.env.REACT_APP_API_URL}/adminuser/fb-logout/${userId}`,{},
        {
            headers: {
                "Authorization": `${localStorage.getItem('token')}`
            }
        }
        ).then(res => {
            // console.log(res);
            if(res.data.status === "success"){
                toast.success('Facebook logged out successfully');
                setGoSteps(0);
            }
        }).catch(err => {
            console.log(err);
            toast.error('Facebook logout failed')
        })
    }
        
    return (
        <div className="dashboard">
            <div className="container px-md-5 px-3 card shadow-sm rounded-3 mb-4 stepper bg-light">
                <Stepper activeStep={goSteps} alternativeLabel>
                <Step onClick={() => setGoStepsOpen(0)} label="Connect with Facebook" />
                <Step onClick={() => setGoStepsOpen(1)} label="Select Ad Account" />
                <Step onClick={() => setGoStepsOpen(2)} label="Select Ad" />
                <Step onClick={() => setGoStepsOpen(3)} label="View Leads" />
                </Stepper>
            </div>

            {loading ? (
                <div className="text-center my-4">
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                    <p className="mt-2">{loading_text}</p>
                </div>
            ):
            <> 
                {goSteps === 0 && (
                    <div className="text-center py-5">
                    <FacebookAuth
                        appId={facebook_client_id}
                        callback={authenticate}
                        component={MyFacebookButton}
                        scope="public_profile, email, ads_read, ads_management, leads_retrieval"
                    />
                    </div>
                )}

                {/* Select Ad Accounts */}
                {goSteps === 1 && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="text-dark">Select Ad Account</h5>
                            <button
                            className="btn btn-dark btn-sm"
                            onClick={(e) => logoutFB(e)}
                            >
                                <DoorClosed className="me-1" /> Logout
                            </button>
                        </div>
                        <div className="row g-3">
                            {adAccounts.length > 0 && !loading ? (
                                adAccounts.map((adAccount, index) => (
                                    <div
                                        className="col-md-6" key={index}
                                        onClick={(e) => getAllAds(adAccount.id)}
                                    >
                                        <div className='card mx-3 border-0 shadow-sm'>
                                            <div className="card-body">
                                                <h6 className="card-title text-primary">{adAccount.name}</h6>
                                                <p className="card-text text-muted">
                                                <small>ID: {adAccount.account_id}</small><br />
                                                <small>{moment(adAccount.created_time).format('MMMM Do YYYY, h:mm:ss a')}</small>
                                                </p>
                                                <button
                                                className="btn btn-primary btn-sm mt-2 w-100"
                                                onClick={(e) => getAllAds(adAccount.id)}
                                                >
                                                <Facebook className="me-1" size={18} /> Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <div className="text-center text-muted">No ad accounts found</div>
                            )}
                        </div>
                    </div>
                )}

                {/* Select Ads */}
                {goSteps === 2 && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="text-dark">Select Ad</h5>
                            <button
                            className="btn btn-dark btn-sm"
                            onClick={(e) => logoutFB(e)}
                            >
                                <DoorClosed className="me-1" /> Logout
                            </button>
                        </div>
                        <div className="row g-3">
                            {ads.length > 0 ? (
                                ads.map((ad, index) => (
                                    <div
                                    className="col-md-6" key={index}
                                    onClick={(e) => getLeads(ad.id)}
                                    >
                                        <div className='card border-0 shadow-sm'>
                                            <div className="card-body">
                                                <h6 className="card-title text-primary">{ad.name}</h6>
                                                <p className="card-text text-muted">
                                                <small>ID: {ad.id}</small><br />
                                                <small>{moment(ad.created_time).format('MMMM Do YYYY, h:mm:ss a')}</small><br />
                                                <span
                                                    className={`badge ${ad.status === 'ACTIVE' ? 'bg-success' : 'bg-warning'}`}
                                                >
                                                    {ad.status}
                                                </span>
                                                </p>
                                                <button
                                                className="btn btn-primary btn-sm mt-2 w-100"
                                                onClick={(e) => getLeads(ad.id)}
                                                >
                                                <Facebook className="me-1" size={18} /> Select
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                            <div className="text-center text-muted">No ads found</div>
                            )}
                        </div>
                    </div>
                )}

                {/* Leads */}
                {goSteps === 3 && (
                    <div>
                        <div className="d-flex justify-content-between align-items-center mb-4">
                            <h5 className="text-dark">Leads</h5>
                            <button
                            className="btn btn-dark btn-sm"
                            onClick={(e) => logoutFB(e)}
                            >
                            <DoorClosed className="me-1" /> Logout
                            </button>
                        </div>
                        <div className="card px-4 py-2">
                            {leads.length > 0 ? (
                                <LeadsDataTable data={leads} />
                            ) : (
                                <div className="text-center text-muted">No leads found</div>
                            )}
                        </div>
                    </div>
                )}
                </>
            }
        </div>
    )
}

export default FbLeads
